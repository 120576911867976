import { render, staticRenderFns } from "./ModuleIconList.vue?vue&type=template&id=5aae04f7&"
import script from "./ModuleIconList.vue?vue&type=script&lang=js&"
export * from "./ModuleIconList.vue?vue&type=script&lang=js&"
import style0 from "./ModuleIconList.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModuleHeader: require('/furanet/sites/runnea.com/web/htdocs/nuxt/components/ModuleHeader.vue').default,IconCard: require('/furanet/sites/runnea.com/web/htdocs/nuxt/components/IconCard.vue').default,ModuleFooter: require('/furanet/sites/runnea.com/web/htdocs/nuxt/components/ModuleFooter.vue').default})
