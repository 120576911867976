//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import generalConfig from '~/static/config/general.json'
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      fb: { src: require('@/assets/img/btn-fb.svg'), alt: 'Facebook Icon' },
      tw: { src: require('@/assets/img/btn-tw.svg'), alt: 'Twitter Icon' },
      wp: { src: require('@/assets/img/btn-wp.svg'), alt: 'Whatsapp Icon' }
    }
  },
  methods: {
    create_social_url (network) {
      let url = ''
      switch (network) {
        case 'facebook':
          url = 'http://www.facebook.com/sharer/sharer.php?u=' + generalConfig.config.url + this.$route.path + '&t=' + this.title
          break
        case 'twitter':
          url = 'http://www.twitter.com/intent/tweet?url=' + generalConfig.config.url + this.$route.path + '&via=' + generalConfig.config.twitter_via + '&text=' + this.title
          break
        case 'whatsapp':
          url = 'whatsapp://send?text=' + generalConfig.config.url + this.$route.path
          break
      }
      return url
    }
  }
}
