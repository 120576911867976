var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container my-4 pt-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-carousel',{attrs:{"id":"carouselHightlight","interval":5000,"indicators":_vm.data.length > 1 ? true : false},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.data),function(item,ind){return _c('b-carousel-slide',{key:ind},[(
              item.title.type &&
              item.title.text.length > 1 &&
              item.title.type === 'destacado'
            )?_c('div',{staticClass:"container my-4 pt-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('h2',{staticClass:"subTitle with-marker"},[_vm._v("\n                  "+_vm._s(item.title.text)+"\n                ")])])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-4 text-center text-md-left",class:item.body.image_align === 'L' ? 'order-first' : 'order-last'},[(item.body.image && item.body.image.src)?_c('div',{staticClass:"d-md-flex align-content-md-center flex-wrap h-100"},[_c('img',{staticClass:"mw-100",attrs:{"src":item.body.image.src,"width":item.body.image.width,"height":item.body.image.height}})]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-8 text-center text-md-left"},[(
                  item.title.type &&
                  item.title.text.length > 1 &&
                  item.title.type === 'h1'
                )?_c('h1',{staticClass:"mainTitle"},[_vm._v("\n                "+_vm._s(item.title.text)+"\n              ")]):(
                  item.title.type &&
                  item.title.text.length > 1 &&
                  item.title.type === 'h2'
                )?_c('h2',{staticClass:"subTitle"},[_vm._v("\n                "+_vm._s(item.title.text)+"\n              ")]):_vm._e(),_vm._v(" "),_vm._l((item.body),function(itemBody,indBody){return [(
                    itemBody.type &&
                    itemBody.type == 'destacado' &&
                    itemBody.text
                  )?_c('h3',{key:indBody,staticClass:"runneanteCircle"},[_vm._v("\n                  "+_vm._s(itemBody.text)+"\n                ")]):_vm._e(),_vm._v(" "),(
                    itemBody.type &&
                    itemBody.type == 'normal' &&
                    itemBody.text
                  )?_c('h3',{key:indBody,staticClass:"runneante"},[_vm._v("\n                  "+_vm._s(itemBody.text)+"\n                ")]):_vm._e(),_vm._v(" "),(
                    itemBody.type &&
                    itemBody.type == 'testimonial' &&
                    itemBody.text
                  )?_c('h3',{key:indBody,staticClass:"testimonial"},[_vm._v("\n                  “"+_vm._s(itemBody.text)+"”\n                ")]):_vm._e(),_vm._v(" "),(
                    itemBody.type && itemBody.type == 'p' && itemBody.text
                  )?_c('span',{key:indBody,staticClass:"mb-4",domProps:{"innerHTML":_vm._s(itemBody.text)}}):_vm._e(),_vm._v(" "),(
                    itemBody.type &&
                    itemBody.type == 'p-italic' &&
                    itemBody.text
                  )?_c('p',{key:indBody,staticClass:"font-italic pb-4"},[_vm._v("\n                  "+_vm._s(itemBody.text)+"\n                ")]):_vm._e(),_vm._v(" "),(
                    itemBody.type &&
                    itemBody.type == 'p-author' &&
                    itemBody.text
                  )?_c('p',{key:indBody,staticClass:"testimonialAuthor"},[_vm._v("\n                  "+_vm._s(itemBody.text)+"\n                ")]):_vm._e()]}),_vm._v(" "),(item.body.links.length > 0)?_c('div',{staticClass:"row"},_vm._l((item.body.links),function(itemLink,indLink){return _c('div',{key:indLink},[_c('div',{class:item.body.links.length > 1 ? 'col-4' : 'col-12'},[_c('a',{key:indLink,staticClass:"rnaButton button",attrs:{"title":itemLink.text,"href":itemLink.url},on:{"click":function($event){return _vm.ga_event('click', itemLink.text)}}},[_vm._v("\n                      "+_vm._s(itemLink.text)+"\n                    ")])])])}),0):_vm._e(),_vm._v(" "),(item.body.image_foot && item.body.image_foot.src)?_c('div',{staticClass:"d-md-flex align-content-md-center flex-wrap",class:item.body.image_foot_align === 'L'
                    ? 'justify-content-md-start'
                    : 'justify-content-md-end'},[_c('img',{staticClass:"mw-100",attrs:{"src":item.body.image_foot.src,"width":item.body.image_foot.width,"height":item.body.image_foot.height}})]):_vm._e()],2)])])}),1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }